// GraciasPage.jsx
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../css/GraciasPage.css';

const GraciasPage = () => {
  useEffect(() => {
    // Agregar el script de Google Analytics solo en esta página
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-PJNNHDXD3X';
    document.head.appendChild(script);

    script.onload = () => {
      // Inicializar Google Analytics cuando el script esté cargado
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-PJNNHDXD3X');
    };

    // Limpiar el script cuando el componente se desmonte
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div className="gracias-container">
      <div className="message-box">
        <h1 className="thanks-message">Gracias por su confianza</h1>
        <p className="sub-message">Pronto nos contactaremos.</p>
        <Link to="/" className="go-home-button">
          Volver al inicio
        </Link>
      </div>
    </div>
  );
};

export default GraciasPage;
